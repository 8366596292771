import {
  Button,
  Container,
  Form,
  InputGroup,
  Nav,
  Navbar,
  NavDropdown,
  Image,
  Dropdown,
} from "react-bootstrap";
import ArrowRightIcon from "../app/assets/images/arrow-right.svg";

import logo from '../../src/app/assets/images/logo.png'
import { Link } from "react-router-dom";

function HomeHeader() {
  return (
    <Navbar collapseOnSelect expand="lg" className="headbg">
      <Container>
        <Navbar.Brand href="/">
          <Image src={logo} className="logoimg" alt="brand" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link as={Link} to="/">Home</Nav.Link>
            <Dropdown className="dropdownmenu">
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                Services
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item as={Link} to="/software-development-services">Software Development</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Mobile App Development</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Website Development</Dropdown.Item>
                <Dropdown.Item href="#/action-4">UI / UX Design Services</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Nav.Link target="_blank" href="https://1techspot.com/blog">Blog</Nav.Link>
            <Nav.Link as={Link} to="/contact-us" className="contbtnlink headerbtn">
              <Button variant="outline-secondary" id="button-addon2">Contact Us <span className="ms-2 cntbtnbg"><Image src={ArrowRightIcon} /></span>
              </Button>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default HomeHeader;
