import React from 'react'
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from './Home';
import Contact from './Contact';
import SoftwareDevelopment from './services/SoftwareDevelopment';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />}/>
        <Route path='/contact-us' element={<Contact />}/>
        <Route path='/software-development-services' element={<SoftwareDevelopment />}/>
      </Routes>
    </BrowserRouter>
  )
}

export default App