import React, { useEffect, useState } from "react";
import HomeHeader from "./components/HomeHeader";
import { Container, Col, Row, Image, Button, Form, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import 'aos/dist/aos.css'

import bannerimg from "../src/app/assets/images/bannerimg.png";
import close from "../src/app/assets/images/closebtn.svg";
import prize from '../src/app/assets/images/prize.png'
import what1 from "../src/app/assets/images/what1.png";
import what2 from "../src/app/assets/images/what2.png";
import what3 from "../src/app/assets/images/what3.png";
import what4 from "../src/app/assets/images/what4.png";
import what5 from "../src/app/assets/images/what5.png";
import what6 from "../src/app/assets/images/what6.png";
import what7 from "../src/app/assets/images/what7.png";
import what8 from "../src/app/assets/images/what8.png";
import what9 from "../src/app/assets/images/what9.png";
import mail from "../src/app/assets/images/mail.png";
import phone from "../src/app/assets/images/phone.png";
import whatsapp from "../src/app/assets/images/whatsapp.png";

import sol from "../src/app/assets/images/sol.png";
import sold from "../src/app/assets/images/sold.png";
import eth from "../src/app/assets/images/eth.png";

import aws from "../src/app/assets/images/aws.webp";
import post from "../src/app/assets/images/post.png";
import nodejs from "../src/app/assets/images/nodejs.webp";
import mongo from "../src/app/assets/images/mongo.webp";
import reacjst from "../src/app/assets/images/reactjs.webp";
import logo from '../src/app/assets/images/logoblack.png'

import FooterMenu from "./components/FooterMenu";
import Aos from "aos";
import ContactForm from "../src/components/ContactForm";
import CtaButtons from "./components/CtaButtons";

const Home = () => {

    const heading = `${'Let'}'s Discuss 👋`
    const subHeading = `Fill in the Form and our Team will be in Touch with you`

    useEffect(() => {
        Aos.init({ duration: 1000 })
    }, [])

    return (
        <>
            <HomeHeader />
            <CtaButtons />
            <section className="homebannerbg">
                <Container>
                    <Row>
                        <Col className="homecolleft" lg={6}>
                            <div className="bannercontent">
                                <h4 className="sub-title">Your Gateway to</h4>
                                <h2 className="maintitl">Digital Excellence</h2>
                                <p className="content">
                                    Our mission is to transform your business ideas into reality
                                    with cutting-edge software solutions.
                                </p>
                            </div>
                        </Col>
                        <Col className="homecolright" lg={6}>
                            <Image src={bannerimg} className="bannerimg" alt="bannerimg" />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="aboutuusection" id="about" data-aos="fade-up">
                <Container>
                    <div className="sectiontitle">
                        <h3 className="sectitl text-center">About Us</h3>
                    </div>
                    <div className="aboutcont">
                        <h2 className="sech2 text-center">What makes 1TechSpot Unique </h2>
                        <p className="text-center content">
                            Welcome to 1TechSpot, where we blend cutting-edge technology with
                            creative innovation. As architects of the digital future, we’re
                            more than just web developers—we’re dream builders.
                        </p>
                        <div className="howitworkscont">
                            <h2 className="secsubtitl text-center">How we started</h2>
                            <p className="text-center content">
                                Our journey began with a tight-knit team of tech enthusiasts who
                                dared to push the boundaries of what’s achievable in the digital
                                realm. From humble beginnings, we’ve grown into a force that
                                shapes the digital landscape.
                            </p>
                        </div>
                        <div className="howitworkscont">
                            <h2 className="secsubtitl text-center">Our Mission</h2>
                            <p className="text-center content">
                                At 1Techspot, We believe in the transformative power of the web.
                                Our commitment is to create digital experiences that resonate,
                                inspire, and leave a lasting impact. Whether it’s crafting
                                seamless mobile apps or architecting robust cloud solutions, we’re
                                driven by innovation.
                            </p>
                        </div>
                        <div className="text-center">
                            <Link to='/contact-us' className="btn sitebtn">Contact Us</Link>
                        </div>
                    </div>
                </Container>
            </section>
            <section className="watwedo" id="services" data-aos="fade-up">
                <Container>
                    <div className="sectiontitle">
                        <h3 className="sectitl text-center">What We Do</h3>
                    </div>
                    <div className="whatwesect my-3">
                        <h2 className="sech2 text-center">We transform your ideas into
                            digital realities</h2>
                        <p className="text-center content">
                            Our passion lies in creating innovative solutions that propel businesses forward.
                        </p>
                    </div>
                    <div className="whatwedoflex">
                        <div className="whatwedocont">
                            <div>
                                <Image src={what1} alt="arrow" className="whatwedoimg" />
                                <div className="whatwedofeatures">
                                    <h2 className="whatwedoh2">Web Development</h2>
                                    <p className="whatwedop">From sleek websites to robust web applications, we build digital platforms that engage users and drive results.</p>
                                </div>
                            </div>
                        </div>
                        <div className="whatwedocont">
                            <Image src={what2} alt="arrow" className="whatwedoimg" />
                            <div className="whatwedofeatures">
                                <h2 className="whatwedoh2">Mobile App Development</h2>
                                <p className="whatwedop">Our mobile app development team crafts intuitive, feature-rich apps for iOS and Android, ensuring seamless user experiences.</p>
                            </div>
                        </div>
                        <div className="whatwedocont">
                            <Image src={what3} alt="arrow" className="whatwedoimg" />
                            <div className="whatwedofeatures">
                                <h2 className="whatwedoh2">Visual Graphic Design</h2>
                                <p className="whatwedop">Our creative graphic designers bring your brand to life with stunning visuals that resonate with your audience.</p>
                            </div>
                        </div>
                        <div className="whatwedocont">
                            <Image src={what4} alt="arrow" className="whatwedoimg" />
                            <div className="whatwedofeatures">
                                <h2 className="whatwedoh2">Software Development</h2>
                                <p className="whatwedop">From sleek websites to robust web applications, we build digital platforms that engage users and drive results.</p>
                            </div>
                        </div>
                        <div className="whatwedocont">
                            <Image src={what5} alt="arrow" className="whatwedoimg" />
                            <div className="whatwedofeatures">
                                <h2 className="whatwedoh2">Blockchain Development</h2>
                                <p className="whatwedop">Revolutionize with blockchain. We develop decentralized applications and systems that enhance security and transparency.</p>
                            </div>
                        </div>
                        <div className="whatwedocont">
                            <Image src={what6} alt="arrow" className="whatwedoimg" />
                            <div className="whatwedofeatures">
                                <h2 className="whatwedoh2">Software Testing</h2>
                                <p className="whatwedop">Quality assurance for flawless performance. Our rigorous testing processes ensure that your software is reliable, secure, and bug-free.</p>
                            </div>
                        </div>
                        <div className="whatwedocont">
                            <Image src={what7} alt="arrow" className="whatwedoimg" />
                            <div className="whatwedofeatures">
                                <h2 className="whatwedoh2">Digital Marketing</h2>
                                <p className="whatwedop">Amplify your digital voice. Our digital marketing strategies are designed to increase your reach, engagement, and conversion rates.</p>
                            </div>
                        </div>
                        <div className="whatwedocont">
                            <Image src={what8} alt="arrow" className="whatwedoimg" />
                            <div className="whatwedofeatures">
                                <h2 className="whatwedoh2">AI & ML Solutions</h2>
                                <p className="whatwedop">Smart solutions for an intelligent future. We harness the power of artificial intelligence and machine learning to provide innovative solutions that drive growth.</p>
                            </div>
                        </div>
                        <div className="whatwedocont">
                            <Image src={what9} alt="arrow" className="whatwedoimg" />
                            <div className="whatwedofeatures">
                                <h2 className="whatwedoh2">UI/UX Design</h2>
                                <p className="whatwedop">Intuitive design for memorable experiences. We focus on creating user interfaces that are both aesthetically pleasing and easy to navigate.</p>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>

            <section className="ourtechstacks" id="techstacks" data-aos="fade-up">
                <Container>
                    <div className="techstackflex">
                        <div className="techstackcircle">
                            <div className="big-circle">
                                <div className="icon-block">
                                    <Image src={reacjst} alt="" className="cicleicons" />
                                </div>
                                <div className="icon-block">
                                    <Image src={nodejs} alt="" className="cicleicons" />
                                </div>
                                <div className="icon-block">
                                    <Image src={mongo} alt="" className="cicleicons" />
                                </div>
                                <div className="icon-block">
                                    <Image src={post} alt="" className="cicleicons" />
                                </div>
                            </div>
                            <div className="circle">
                                <div className="icon-block">
                                    <Image src={aws} alt="" className="cicleicons" />
                                </div>
                                <div className="icon-block">
                                    <Image src={sol} alt="" className="cicleicons" />
                                </div>
                                <div className="icon-block">
                                    <Image src={sold} alt="" className="cicleicons" />
                                </div>
                                <div className="icon-block">
                                    <Image src={eth} alt="" className="cicleicons" />
                                </div>
                            </div>
                            <div className="center-logo">
                                <Image src={logo} alt="logo" className="centerlogo" />
                            </div>
                        </div>
                        <div className="techstackflexcont">
                            <div className="sectiontitle">
                                <h2 className="sech2">Our Tech Stack ⚡</h2>
                                <p className="content">We build innovative, automated solutions with futuristic tech to reduce manual work, solving today’s challenges while preparing for tomorrow.</p>
                            </div>
                            <div className="">
                                <Link to='/contact-us' className="btn sitebtn">Get Started</Link>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>

            <section className="saassection" data-aos="fade-up">
                <Container>
                    <div className="saascont">
                        <h2 className="sech2 text-center">Our Saas Products Will Be</h2>
                        <h1 className="sech1 text-center my-3">Coming Here Soon!</h1>
                        <p className="text-center content">We are hoping to Fund Our very Own Saas Product which is Under Development, By Providing Cost effecient and High Quality IT Solution & Services to Our Clients.</p>
                    </div>
                </Container>
            </section>
            <section className="contactsection" id="contact" data-aos="fade-up">
                <Container>
                    <div className="conttop col-lg-8 mx-auto">
                        <div className="sectiontitle">
                            <h3 className="sectitl text-center"><span className="contactspan">Contact Us</span></h3>
                            <h2 className="sech2 text-center">Speak With Our Friendly Team</h2>
                        </div>
                    </div>
                </Container>
            </section>
            <section className="contactbtmsection" data-aos="fade-up">
                <Container>
                    <Row>
                        <Col>
                            <div className="contbtm">
                                <div className="contcontent">
                                    <Image src={whatsapp} alt="whatsapp" className="contimg" />
                                    <h2 className="conth2">Chat With Us</h2>
                                    <p className="contp">Speak to us in Whatsapp</p>
                                    <a target="_blank" href="https://wa.me/9487734068"><p className="contl">+91 9940722428</p></a>
                                </div>
                                <div className="contcontent">
                                    <Image src={phone} alt="phone" className="contimg" />
                                    <h2 className="conth2">Call Us</h2>
                                    <p className="contp">Mon-Fri 9:30 to 6:00</p>
                                    <a target="_blank" href="https://t.me/Hello1TechSpot"><p className="contl">+91 9940722428</p></a>
                                </div>
                                <div className="contcontent">
                                    <Image src={mail} alt="mail" className="contimg" />
                                    <h2 className="conth2">Mail Us</h2>
                                    <p className="contp">We're here to help</p>
                                    <a href="#"><p className="contl">hello@1techspot.com</p></a>
                                </div>
                            </div>

                            <ContactForm heading={heading} subHeading={subHeading} />
                        </Col>
                    </Row>
                </Container>
            </section>



            <FooterMenu />
        </>
    );
};

export default Home;
